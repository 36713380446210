import ActionDropdown from "../../ActionDropdown";
import TableCheck from "../TableCheck";
import DateFormatter from "../../DateFormatter";
import { dateFine } from "../../../util/Utilities";
import { Dropdown } from "react-bootstrap";
import { mainPermissions } from "../../../util/MenuLinks";
import UserHavePermission from "../../../util/UserHavePermission";

const FeaturesColumns = [
    {
        Label: TableCheck,
        Component: TableCheck

    },
    {
        Label: () => 'id',
        accessor: 'id'
    },
    {
        Label: () => 'Nombre',
        accessor: 'name'
    },
    {
        Label: () => 'Valores',
        Component: ({ optionsCount }) => optionsCount
    },
    {
        Label: () => 'Fecha de Creación',
        Component: ({ value }) => <DateFormatter value={dateFine(value?.createdAt)} dateFormat="dd/MM/yyyy hh:mm:ss" />
    },
    {
        Label: () => 'Acciones',
        Component: ({ value, onDelete }) => {
            return (
                <Dropdown className="dropdown ms-auto text-right">
                    <Dropdown.Toggle
                        variant=""
                        className="btn-link i-false"
                        data-toggle="dropdown"
                    >
                        <svg
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                        >
                            <g
                                stroke="none"
                                strokeWidth={1}
                                fill="none"
                                fillRule="evenodd"
                            >
                                <rect x={0} y={0} width={24} height={24} />
                                <circle fill="#000000" cx={5} cy={12} r={2} />
                                <circle fill="#000000" cx={12} cy={12} r={2} />
                                <circle fill="#000000" cx={19} cy={12} r={2} />
                            </g>
                        </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                        {
                            UserHavePermission(mainPermissions?.features?.[3]) ?
                                <Dropdown.Item onClick={() => onDelete?.(value)} >
                                    Eliminar
                                </Dropdown.Item>
                                : null
                        }
                        {
                            UserHavePermission(mainPermissions?.features?.[3]) ?
                                <Dropdown.Item href={`/caracteristicas/${value?.id}`} >
                                    Editar
                                </Dropdown.Item>
                                : null
                        }
                    </Dropdown.Menu>
                </Dropdown>
            )
        }
    }
];

export default FeaturesColumns;

