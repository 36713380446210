import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFeedBack } from "../../../../context/FeedBackContext";
import { useOrderCrud } from "../../../../context/OrderCrudContext";
import useAxios from "../../../../hooks/useAxios";
import update from 'immutability-helper';
import useProducts from "../../../../hooks/useProducts";
import SystemInfo from "../../../../util/SystemInfo";
import { cutString } from "../../../../util/Utilities";
import { Droppable, DragDropContext, Draggable } from 'react-beautiful-dnd';
import { Button, Modal } from "react-bootstrap";
import useCategories from "../../../../hooks/useCategories";
import clsx from "clsx";
import { useTheme } from "../../../../context/ThemeContext";
import swal from "sweetalert";
import TemplatesModal from "./TemplatesModal";
import imgUrl from "../../../../util/imgUrl";
import CreateOrderItemRow from "./CreateOrderItemRow";
import ManualOrderForm from "../../Orders/ManualOrderForm";
import OrdersCreateFiltersForm from "../../Orders/OrdersCreateFiltersForm";


const StepFour = () => {
    const navigate = useNavigate();

    const { setCustomAlert } = useFeedBack();

    const { background } = useTheme();

    const [showModal, setShowModal] = useState(false);

    const [detailProduct, setDetailProduct] = useState(null);

    const [showDetails, setShowDetails] = useState(false);

    const [filters, setFilters] = useState({
        serviceId: '',
        isReplacement: false,
        name: '',
        reference: '',
        providerName: '',
        code: '',
        page: 1,
        subCategoryId: '',
        categoryId: '',
        withChildren: true,
        parentsOnly: true,
        perPage: 8
    });

    const { data, setData, setCurrentStep } = useOrderCrud();

    const [currentProducts, setCurrentProducts] = useState([]);

    const [{ data: service, loading: loadingService }, getService] = useAxios({ url: `/services/${data?.serviceId}` }, { useCache: false, manual: true });

    const [{ data: orderType, loading: loadingOrderType }, getOrderType] = useAxios({ url: `/order-types/${data?.orderTypeId}` }, { useCache: false, manual: true });

    const [{ products, total: productsTotal, numberOfPages, loading: loadingProducts }, getProducts] = useProducts({ params: { ...filters }, options: { useCache: false } });

    const [{ data: createData, loading: createLoading, error: createError }, createOrder] = useAxios({ url: `/orders`, method: 'POST' }, { manual: true, useCache: false });

    const [{ data: deleteAutoSaveResponse }, deleteAutoSave] = useAxios({ url: `/auto-saves`, method: 'DELETE' }, { useCache: false, manual: true });

    const [total, setTotal] = useState(0);

    useEffect(() => {
        if (products?.length > 0) {
            setCurrentProducts(products);
        }
    }, [products]);

    useEffect(() => {
        setCurrentProducts([]);
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                page: 1
            }
        });
    }, [filters?.name, filters?.serviceId, filters?.reference, filters?.providerName, filters?.price, filters?.isReplacement, filters?.serviceId, filters?.subCategoryId, filters?.categoryId])

    useEffect(() => {
        if (data?.serviceId) {
            getService();
        }
    }, [data?.serviceId])

    useEffect(() => {
        if (data?.orderTypeId) {
            getOrderType();
        }
    }, [data?.orderTypeId])

    useEffect(() => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                serviceId: data?.serviceId || '',
                isReplacement: data?.isReplacement ? 'true' : 'false',
                isProduct: data?.isReplacement ? 'false' : 'true',
            }
        });
    }, [data?.serviceId, data?.isReplacement])

    useEffect(() => {
        var total = 0;
        data?.orderItems?.forEach((item) => {
            total = total + (item?.price * item?.quantity)
        });

        setTotal(total);
    }, [data?.orderItems]);

    useEffect(() => {
        if (createData) {
            setCustomAlert({
                title: '¡Operacion Exitosa!',
                severity: 'success',
                message: 'El pedido fue creado exitosamente.',
                show: true
            });
            deleteAutoSave({
                data: {
                    for: 'order'
                }
            }).then(() => {
                navigate('/mis-pedidos');
            });
        }
    }, [createData]);

    useEffect(() => {
        if (createError) {
            setCustomAlert({
                title: 'Error',
                severity: 'danger',
                message: 'Ha ocurrido un error.',
                show: true
            });
        }
    }, [createError]);

    const handleDragEnd = (e) => {
        const { source, destination } = e;

        if (!destination) return;

        if (source?.droppableId === destination?.droppableId) return;

        const product = products.find(x => x.id == e.draggableId);

        const orderHasProduct = data?.orderItems?.find(x => x.id === product.id);

        if (orderHasProduct) {
            alert('El producto ye se encuentra en el pedido.');
            return;
        }

        setData((oldData) => {
            return {
                ...oldData,
                orderItems: [
                    ...oldData?.orderItems,
                    {
                        id: product?.id,
                        name: product?.name,
                        quantity: 1,
                        price: product?.price,
                        code: product?.code,
                    }
                ]
            }
        })
    }

    const handleRemove = (index) => {
        data?.orderItems?.splice(index, 1);

        var total = 0;

        data?.orderItems?.forEach((item) => {
            total = item?.price * item?.quantity + total;
        });

        setTotal(total);

        setData((oldData) => {
            return {
                ...oldData,
                orderItems: data?.orderItems
            }
        });
    }

    const handleArrayChange = (e, index, arrayName) => {
        const newArrayValues = update(data?.[arrayName], { [index]: { [e.target.name]: { $set: e.target.value } } });
        setData((oldData) => {
            return {
                ...oldData,
                [arrayName]: newArrayValues
            }
        });
    }

    const showProductDetails = (product) => {
        setDetailProduct(product);
        setShowDetails(true);
    }

    const handleToggleVersion = (productVersion) => {

        const res = orderHasProduct(productVersion?.id);

        if (res?.have) {
            data?.orderItems?.splice(res?.index, 1);

            var total = 0;

            data?.orderItems?.forEach((item) => {
                total = item?.price * item?.quantity + total;
            });

            setTotal(total);

            setData((oldData) => {
                return {
                    ...oldData,
                    orderItems: data?.orderItems
                }
            });
        } else {
            setData((oldData) => {
                return {
                    ...oldData,
                    orderItems: [
                        ...oldData?.orderItems,
                        {
                            name: `${productVersion?.name}`,
                            quantity: 1,
                            price: productVersion?.price,
                            code: productVersion?.code,
                            id: productVersion?.id
                        }
                    ]
                }
            });
        }
    }

    const orderHasProduct = ($id) => {
        const itemIndex = data?.orderItems?.findIndex(x => x.id === $id);

        if (itemIndex >= 0) {
            return { have: true, index: itemIndex };
        } else {
            return false;
        }
    }

    const handleBack = () => {
        swal({
            title: "¿Estás Seguro?",
            text: "Esto eliminara todo lo que hicistes hasta ahora.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                setData((oldData) => {
                    return {
                        ...oldData,
                        orderItems: []
                    }
                });
                setCurrentStep((oldStep) => {
                    return oldStep - 1
                });
            } else {

            }
        })
    }

    const handleCreateOrder = () => {
        const {
            isReplacement,
            orderItems,
            authorizedBy,
            account,
            seven,
            chargePerForm,
            saveTemplate,
            ...rest
        } = data;

        var formData = new FormData();

        if (data?.orderTypeId == 3) {
            Object.keys(rest).forEach((key, i) => {
                formData?.append(key, rest[key]);
            });

            if (data?.chargePerForm) {
                formData?.append('authorizedBy', data?.authorizedBy);
                formData?.append('account', data?.account);
                formData?.append('seven', data?.seven);
            }

            formData?.append('isReplacement', isReplacement ? 1 : 0);

            orderItems?.forEach((item, i) => {
                formData.append(`customOrderItems[${i}][name]`, item?.name);
                if (item?.image) {
                    formData.append(`customOrderItems[${i}][image]`, item?.image, item?.image?.name);
                }

                if (item?.file) {
                    formData.append(`customOrderItems[${i}][file]`, item?.file, item?.file?.name);
                }

                formData.append(`customOrderItems[${i}][providerName]`, item?.providerName);
                formData.append(`customOrderItems[${i}][description]`, item?.description);
                formData.append(`customOrderItems[${i}][quantity]`, item?.quantity);
                formData.append(`customOrderItems[${i}][price]`, item?.price);
                formData.append(`customOrderItems[${i}][type]`, item?.type);
            });
        } else {
            formData = {
                ...rest,
                isReplacement: isReplacement ? 1 : 0,
                saveTemplate: saveTemplate ? 1 : 0,
                orderItems: orderItems?.map((item, i) => {
                    return {
                        productId: item?.id,
                        quantity: item?.quantity,
                    }
                })
            }
        }

        createOrder({ data: formData });
    }

    const handleTemplate = (template) => {

        setData((oldData) => {
            return {
                ...oldData,
                orderItems: template?.items?.map((item) => {
                    return {
                        id: item?.id,
                        quantity: item?.quantity,
                        name: item?.name,
                        price: item?.price,
                        code: item?.code
                    }
                })
            }
        });

        setShowModal(false);

    }

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <div className="container-fluid mb-5">
                <div className="row">
                    {
                        data?.orderTypeId == 3 ?
                            <ManualOrderForm data={data} setData={setData} />
                            :
                            <div className="col-12 col-lg-6">
                                <h1 className="text-center">{data?.isReplacement ? 'Repuestos' : 'Productos'}</h1>

                                <OrdersCreateFiltersForm filters={filters} setFilters={setFilters} />

                                <Droppable droppableId="products">
                                    {(droppableProvided) => <div
                                        {...droppableProvided?.droppableProps}
                                        ref={droppableProvided?.innerRef}
                                        className="row align-items-center"
                                    >
                                        {
                                            currentProducts?.length > 0 ?
                                                currentProducts?.map((product, i) => {
                                                    return (
                                                        <Draggable key={i} draggableId={`${product?.id}`} index={i}>
                                                            {(draggableProvided) => <div
                                                                {...draggableProvided?.draggableProps}
                                                                ref={draggableProvided?.innerRef}
                                                                {...draggableProvided?.dragHandleProps}
                                                                className="col-6 col-md-4 col-xl-3 mb-4 animate__animated animate__fadeIn"
                                                            >
                                                                <div
                                                                    className="rounded position-relative"
                                                                    style={{ overflow: 'hidden', background: background?.value === 'light' ? 'white' : '#171622' }}
                                                                >
                                                                    <input
                                                                        className="position-absolute"
                                                                        style={{ top: 5, right: 5 }}
                                                                        onChange={() => { handleToggleVersion(product) }}
                                                                        checked={orderHasProduct(product?.id)}
                                                                        type={'checkbox'}
                                                                    />
                                                                    <div
                                                                        className="text-center"
                                                                        style={{
                                                                            minHeight: '100px',
                                                                            backgroundImage: `url(${imgUrl(product?.imagePath)})`,
                                                                            backgroundPosition: 'center',
                                                                            backgroundSize: '100%'
                                                                        }}

                                                                    >
                                                                    </div>
                                                                    <div className="p-1">
                                                                        <div title={product?.name}>
                                                                            <h6>{cutString(product?.name, 20, 0, '...')}</h6>
                                                                        </div>
                                                                        <div title="Código">
                                                                            <b>{product?.code}</b>
                                                                        </div>
                                                                        <div>
                                                                            <span>
                                                                                Categoría: <b>{product?.category?.name || ''}</b>
                                                                            </span>
                                                                        </div>
                                                                        <div className="text-center mt-2">
                                                                            <button onClick={() => { showProductDetails(product) }} className="btn btn-primary btn-xs">
                                                                                Tallas y colores
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            }
                                                        </Draggable>
                                                    )
                                                })
                                                :
                                                !loadingProducts &&
                                                <div className="text-center">
                                                    No se encontraron productos.
                                                </div>
                                        }
                                        {
                                            loadingProducts &&
                                            <div className="col-md-4 animate__animated animate__fadeIn">
                                                Cargando...
                                            </div>
                                        }
                                        {
                                            droppableProvided?.placeholder
                                        }
                                    </div>
                                    }
                                </Droppable>
                                <div
                                    className="d-flex col-12 justify-content-between mt-5 mb-5 mb-md-0"
                                >
                                    <button
                                        className="btn btn-primary btn-xs"
                                        disabled={filters?.page <= 1}
                                        style={{ border: 'none !important' }}
                                        onClick={() =>
                                            filters?.page > 1 && setFilters((oldFilters) => {
                                                return {
                                                    ...oldFilters,
                                                    page: oldFilters?.page - 1
                                                }
                                            })
                                        }
                                    >
                                        <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                        Anterior
                                    </button>
                                    <span className="custom-horizontal-scrollbar scrollbar-primary" style={{ maxWidth: '100%', display: 'flex', overflowX: 'auto' }}>
                                        {Array.from(Array(numberOfPages).keys()).map((number, i) => (
                                            <div
                                                key={i}
                                                className={`${filters?.page === (i + 1) ? "current bg-primary text-white" : ""}`}
                                                onClick={() => {
                                                    setFilters((oldFilters) => {
                                                        return {
                                                            ...oldFilters,
                                                            page: 1 + number
                                                        }
                                                    })
                                                }}
                                                style={{
                                                    marginRight: '10px',
                                                    padding: '10px 15px',
                                                    borderRadius: '10px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                {1 + number}
                                            </div>
                                        ))}
                                    </span>
                                    <button
                                        className="btn btn-primary btn-xs"
                                        disabled={filters?.page >= numberOfPages}
                                        onClick={() =>
                                            filters?.page < numberOfPages &&
                                            setFilters((oldFilters) => {
                                                return {
                                                    ...oldFilters,
                                                    page: oldFilters?.page + 1
                                                }
                                            })
                                        }
                                    >
                                        Siguiente
                                        <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                    }
                    <div className="col-12 col-lg-6">
                        {
                            data?.orderTypeId != 3 &&
                            <div className="text-center">
                                <button onClick={() => { setShowModal(true) }} className="btn btn-primary mb-2">
                                    Cargar Copia
                                    <i className="flaticon-381-add mx-2"></i>
                                </button>
                            </div>
                        }
                        <Droppable droppableId="orderItems">
                            {
                                (droppableProvided, snapshot) => <div
                                    {...droppableProvided?.droppableProps}
                                    ref={droppableProvided?.innerRef}
                                    className={clsx(["rounded shadow-sm", {
                                        "bg-white": background?.value === 'light',
                                        "card": background?.value !== 'light',
                                    }])}
                                    style={{ position: 'relative', overflow: 'hidden', minHeight: '60vh', height: 'fit-content' }}
                                >
                                    {
                                        snapshot.isDraggingOver &&
                                        <div className="animate__animated animate__fadeIn" style={{ display: 'flex', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 10, background: 'rgba(0,0,0, .5)' }}>
                                            <h5 className="m-auto text-white animate__animated animate__fadeInUp animate__faster">
                                                Suelte el producto para añadirlo
                                            </h5>
                                        </div>
                                    }
                                    <div className="card-header">
                                        <h3 className="card-title">Pedido</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row mb-2">
                                            <div className="col-md-6">
                                                Tipo de pedido:
                                            </div>
                                            <div className="col-md-6 text-end">
                                                {
                                                    loadingOrderType ?
                                                        <b>Cargado...</b>
                                                        :
                                                        <b>{orderType?.data?.displayText}</b>
                                                }
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-md-6">
                                                Servicio:
                                            </div>
                                            <div className="col-md-6 text-end">
                                                {
                                                    loadingService ?
                                                        <b>Cargado...</b>
                                                        :
                                                        <b>{service?.data?.name}</b>
                                                }
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-md-6">
                                                Es Para Repuestos:
                                            </div>
                                            <div className="col-md-6 text-end">
                                                <b>{data?.isReplacement ? 'SI' : 'NO'}</b>
                                            </div>
                                        </div>
                                        <div className="text-center mt-2">
                                            <h4 style={{ marginBottom: 0 }}>{data?.isReplacement ? 'Repuestos' : 'Productos'}</h4>
                                        </div>
                                        <div className="table-responsive" style={{ maxHeight: '75vh' }}>
                                            <table className="table table-order text-center" style={{ fontSize: '10px' }}>
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Código</th>
                                                        <th width={"30%"} colSpan={2}>Item</th>
                                                        <th>Cant.</th>
                                                        <th>Precio</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data?.orderItems?.length > 0 ?
                                                            data?.orderItems?.map((item, i) => {
                                                                return (
                                                                    <CreateOrderItemRow
                                                                        onChange={(e, i) => handleArrayChange(e, i, 'orderItems')}
                                                                        index={i}
                                                                        item={item}
                                                                        onRemove={handleRemove}
                                                                    />
                                                                )
                                                            })
                                                            :
                                                            <tr className="text-center">
                                                                <td colSpan={7} style={{ fontSize: 15 }}>
                                                                    <p>{`Añada ${data?.isReplacement ? 'repuestos' : 'productos'} al pedido`}</p>
                                                                </td>
                                                            </tr>
                                                    }
                                                    <tr>
                                                        <td colSpan={5}>
                                                            <h3>Total:</h3>
                                                        </td>
                                                        <td colSpan={2}>
                                                            <h3>$ {total}</h3>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="card-footer text-center">
                                        {
                                            data?.orderTypeId != 3 &&
                                            <div className="mb-3">
                                                <label className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                                                    <input
                                                        type="checkbox"
                                                        style={{ cursor: 'pointer' }}
                                                        checked={data?.saveTemplate}
                                                        onChange={(e) => {
                                                            setData((oldData) => {
                                                                return {
                                                                    ...oldData,
                                                                    saveTemplate: !oldData?.saveTemplate
                                                                }
                                                            })
                                                        }}
                                                    />
                                                    <span style={{ marginLeft: 10 }}>
                                                        ¿Guardar este pedido como plantilla?
                                                    </span>
                                                </label>
                                            </div>
                                        }
                                        <button type="button" className="btn btn-danger mx-1" onClick={handleBack}>
                                            Volver
                                        </button>
                                        <button disabled={createLoading} onClick={handleCreateOrder} className="btn btn-success mx-1">
                                            {
                                                createLoading ?
                                                    'Cargando...'
                                                    :
                                                    'Crear Pedido'
                                            }
                                        </button>
                                    </div>
                                </div>
                            }
                        </Droppable>
                    </div>
                </div>
            </div>
            <Modal show={showDetails} className="fade" size="lg">
                <Modal.Header>
                    <Modal.Title>{detailProduct?.name}</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setShowDetails(false)}
                    >
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-4">
                            <img
                                src={imgUrl(detailProduct?.imagePath)}
                                style={{ maxWidth: '100%', borderRadius: '10px' }}
                                alt=""
                            />
                        </div>
                        <div className="col-md-4">
                            <div className="form-group my-2">
                                <b>Descripción: </b>
                                {detailProduct?.description}
                            </div>
                            <div className="form-group my-2">
                                <b>Código: </b>
                                {detailProduct?.code}
                            </div>
                            <div className="form-group my-2">
                                <b>Precio: </b>
                                ${detailProduct?.price}
                            </div>
                            <div className="form-group my-2">
                                <b>Categoría: </b>
                                {detailProduct?.category?.name}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h4>Versiones</h4>
                        </div>
                        <table className="table text-center">
                            <thead>
                                <tr>
                                    <th>imagen</th>
                                    <th>Nombre</th>
                                    <th>Código</th>
                                    <th>Seleccionar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    detailProduct?.children?.length > 0 ?
                                        detailProduct?.children?.map?.((childProduct, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <img
                                                            style={{ maxWidth: '50px', height: '50px' }}
                                                            src={imgUrl(childProduct?.imagePath)} />
                                                    </td>
                                                    <td>
                                                        {childProduct?.name}
                                                    </td>
                                                    <td>
                                                        {childProduct?.code}
                                                    </td>
                                                    <td>
                                                        <input
                                                            onChange={() => { handleToggleVersion(childProduct) }}
                                                            checked={orderHasProduct(childProduct?.id)}
                                                            type={'checkbox'}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <tr>
                                            <td colSpan={4}>
                                                <h4 className="text-danger">
                                                    No hay versiones.
                                                </h4>
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => { setShowDetails(false) }} className="btn btn-danger">
                        Cerrar
                    </button>
                </Modal.Footer>
            </Modal>
            <TemplatesModal isReplacement={data?.isReplacement} show={showModal} onClose={() => setShowModal(false)} onSelectTemplate={handleTemplate} />
        </DragDropContext>
    )
}

export default StepFour;