import ActionDropdown from "../../ActionDropdown";
import TableCheck from "../TableCheck";
import ImageAndName from "../../ImageAndName";
import DateFormatter from "../../DateFormatter";
import { dateFine } from "../../../util/Utilities";

const RequestedProductColumn = [
    {
        Label: TableCheck,
        Component: TableCheck

    },
    {
        Label: () => 'Código',
        Component: ({ value }) => `${value?.productCode ?? '--'}`
    },
    {
        Label: () => 'Nombre',
        Component: ({ value }) => `${value?.name}`
    },
    {
        Label: () => 'Cantidad',
        Component: ({ value }) => `${value?.quantity}`
    },
    {
        Label: () => 'Precio',
        Component: ({ value }) => `$${value?.price}`
    },
    {
        Label: () => 'Pedido',
        Component: ({ value }) => `${value?.orderId}`
    },
    {
        Label: () => 'Servicio',
        Component: ({ value }) => `${value?.service?.name}`
    },
    {
        Label: () => 'Estado',
        Component: ({ value }) => <div className={`btn btn-xs btn-${value?.status?.variant_color}`} >
            {value?.status?.name}
        </div>
    },
    {
        Label: () => 'Proveedor',
        Component: ({ value }) => value?.providerName
    },
    {
        Label: () => 'Fecha de Creación',
        Component: ({ value }) => <DateFormatter value={dateFine(value?.createdAt)} dateFormat="dd/MM/yyyy hh:mm:ss" />
    }
];

export default RequestedProductColumn;

