import ActionDropdown from "../../ActionDropdown";
import TableCheck from "../TableCheck";
import ImageAndName from "../../ImageAndName";
import DateFormatter from "../../DateFormatter";
import { dateFine } from "../../../util/Utilities";

const GroupedRequestedProductColumn = [
    {
        Label: () => 'Nombre',
        Component: ({ value }) => `${value?.name}`
    },
    {
        Label: () => 'Cantidad',
        Component: ({ value }) => `${value?.quantity}`
    },
    {
        Label: () => 'Total',
        Component: ({ value }) => `$${value?.price}`
    },
    {
        Label: () => 'Estado',
        Component: ({ value }) => <div className={`btn btn-xs btn-${value?.status_variant_color}`} >
            {value?.status_name}
        </div>
    },
    {
        Label: () => 'Proveedor',
        Component: ({ value }) => value?.provider_name
    },
];

export default GroupedRequestedProductColumn;

