import { useEffect, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import CustomTable from "../../../components/CustomTable/CustomTable";
import AsyncSelect from "react-select/async";
import { handleLoadSelectOptions, mapValues } from "../../../util/Utilities";
import useServices from "../../../hooks/useServices";
import useOrderStatuses from "../../../hooks/useOrderStatuses";
import RequestedProductColumn from "../../../components/CustomTable/Columns/RequestedProductColumn";
import useProviders from "../../../hooks/useProviders";
import { FaRegClock, FaTimesCircle } from "react-icons/fa";
import { BsCartCheck } from "react-icons/bs";
import { Button, Modal } from "react-bootstrap";
import GroupedRequestedProductColumn from "../../../components/CustomTable/Columns/GroupedRequestedProductColumn";


const RequestedProducts = () => {

    const [filters, setFilters] = useState({
        page: 1,
        perPage: 10,
        name: '',
        orderId: '',
        orderStatusCode: '',
        start: '',
        end: '',
        userProviderResponsible: true
    });

    const [selectedValues, setSelectedValues] = useState([]);

    const [showFor, setShowFor] = useState('');

    const [statusCode, setStatus] = useState('');

    const [description, setDescription] = useState('');

    const [selectAll, setSelectAll] = useState(false);

    const [{ data, loading }, getProducts] = useAxios({ url: `/order-items${showFor}`, params: filters }, { useCache: false });

    const [{ loading: loadingStatuses }, updateStatuses] = useAxios({ url: `/order-items/update-status/multiple`, method: 'post' }, { useCache: false, manual: true });

    const [{ services, loading: servicesLoading }, getServices] = useServices({ params: { perPage: 80 } }, { useCache: false });

    const [{ providers, loading: providersLoading }, getProviders] = useProviders({ params: { perPage: 80, userProviders: true } }, { useCache: false });

    const [{ orderStatuses }, getOrderStatuses] = useOrderStatuses({ options: { useCache: false } });

    useEffect(() => {
        if (selectAll) {
            setSelectedValues(data?.data?.map?.((value) => value?.id))
        } else {
            setSelectedValues([])
        }
    }, [selectAll])

    const handleSelectValue = (selectedValue) => {
        const value = selectedValues?.includes(Number(selectedValue?.id));
        if (value) {
            const newValues = selectedValues?.filter(n => n !== Number(selectedValue?.id));
            setSelectedValues(newValues);
        } else {
            setSelectedValues((oldSelectedValues) => [...oldSelectedValues, Number(selectedValue?.id)])
        }
    }

    const handleChange = (e) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value,
                page: 1
            }
        });
    }

    const handleSelectALL = () => {
        setSelectAll((oldSelectAll) => !oldSelectAll);
    }

    const handlePageChange = (page) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                page: page
            }
        })
    }

    const handleConfirm = () => {
        updateStatuses?.({
            data: {
                status_code: statusCode,
                itemsIds: selectedValues,
                description
            }
        }).then(() => {
            getProducts?.();
            setStatus('');
            setSelectedValues([]);
        });
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-3">
                    <div className="card p-4">
                        <label>
                            Nombre
                        </label>
                        <input
                            placeholder="Nombre..."
                            type="text"
                            name="name"
                            value={filters?.name}
                            onChange={handleChange}
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card p-4">
                        <label>
                            Número de pedido
                        </label>
                        <input
                            placeholder="Número de pedido..."
                            type="text"
                            name="name"
                            value={filters?.orderId}
                            onChange={handleChange}
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card p-4">
                        <label className="text-primary">
                            Servicio
                        </label>
                        <AsyncSelect
                            isClearable
                            onFocus={() => {
                                getServices({
                                    params: {
                                        perPage: 80,
                                        page: 1,
                                    }
                                })
                            }}
                            isLoading={servicesLoading}
                            defaultOptions={mapValues(services)}
                            name="serviceId"
                            loadOptions={(e) => handleLoadSelectOptions(e, getServices, { perPage: 80, page: 1 })}
                            placeholder='Escriba el nombre para buscar...'
                            onChange={(e) => { handleChange({ target: { value: e?.value, name: 'serviceId' } }) }}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card p-4">
                        <label className="text-primary">
                            Proveedor
                        </label>
                        <AsyncSelect
                            isClearable
                            onFocus={() => {
                                getProviders({
                                    params: {
                                        perPage: 80,
                                        page: 1,
                                        userProviders: true
                                    }
                                })
                            }}
                            isLoading={providersLoading}
                            defaultOptions={mapValues(providers)}
                            name="providerId"
                            loadOptions={(e) => handleLoadSelectOptions(e, getProviders, { perPage: 80, page: 1, userProviders: true })}
                            placeholder='Escriba el nombre para buscar...'
                            onChange={(e) => { handleChange({ target: { value: e?.value, name: 'providerId' } }) }}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card p-4">
                        <label className="text-primary">
                            Estatus
                        </label>
                        <select name="orderStatusCode" className="form-control" value={filters?.orderStatusCode} onChange={handleChange}>
                            <option value="">Seleccione uno</option>
                            {
                                orderStatuses?.map((orderStatus, i) => {
                                    return (
                                        <option key={i} value={orderStatus?.code}>
                                            {orderStatus?.name}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card p-4">
                        <label className="text-primary">
                            Mostrar
                        </label>
                        <select className="form-control" value={showFor} onChange={(e) => setShowFor(e.target.value)}>
                            <option value="">Detallado</option>
                            <option value="/grouped">Agrupado</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card p-4">
                        <label className="text-primary">
                            Fecha
                        </label>
                        <div className="row">
                            <div className="col-md-6 form-group">
                                <label className="text-primary">
                                    Desde:
                                </label>
                                <input
                                    type="date"
                                    onChange={handleChange}
                                    className="form-control"
                                    name="start"
                                    value={filters?.start}
                                />
                            </div>
                            <div className="col-md-6 form-group">
                                <label className="text-primary">
                                    Hasta:
                                </label>
                                <input
                                    type="date"
                                    onChange={handleChange}
                                    className="form-control"
                                    name="end"
                                    value={filters?.end}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CustomTable
                onSelectValue={handleSelectValue}
                withoutGlobalActions
                onSelectAll={handleSelectALL}
                loading={loading}
                selectAll={selectAll}
                title={'Productos'}
                entity='products'
                updatePath={"/productos"}
                selectedValues={selectedValues}
                pages={data?.meta?.last_page}
                total={data?.meta?.total}
                values={data?.data}
                currentPage={filters.page}
                collumns={!showFor ? RequestedProductColumn : GroupedRequestedProductColumn}
                changePage={handlePageChange}
            />
            {
                selectedValues?.length > 0 &&
                <div class="col-lg-6 col-12 px-5 px-md-0 position-fixed"
                    style={{ bottom: '10px', left: '50%', transform: 'translate(-50%, -10%)', zIindex: 50 }}>
                    <div class="rounded bg-white position-relative animate__animated animate__fadeInUp"
                        style={{ height: 'fit-content', boxShadow: '0 8px 24px #374d6326' }} >
                        <button class="btn btn-xs" style={{ position: 'absolute', fontSize: '24px', top: '-20px', right: '-20px' }} onClick={() => setSelectedValues([])}>
                            <FaTimesCircle
                                className="text-danger"
                            />
                        </button>
                        <div class="d-flex align-items-center global-actions-container">
                            <div class="w-100 bg-primary rounded d-flex align-items-center justify-content-center h-100">
                                <h1 class="mb-0 text-white text-center">
                                    {selectedValues?.length}
                                </h1>
                            </div>
                            <div class="w-100 rounded h-100">
                                <button onClick={() => setStatus('ors-001')}
                                    class="btn h-100 w-100 text-warning d-flex align-items-center justify-content-center"
                                    style={{ flexWrap: 'wrap' }} disabled={loadingStatuses}>
                                    {
                                        loadingStatuses ?
                                            <div className="spinner">
                                                <div className="double-bounce1 bg-primary"></div>
                                                <div className="double-bounce2 bg-primary"></div>
                                            </div>
                                            :
                                            <>
                                                <FaRegClock className="mb-0" />
                                                <p class="mb-0 w-100 text-center" style={{ fontSize: '14px' }}>
                                                    Pendiente
                                                </p>
                                            </>
                                    }
                                </button>
                            </div>
                            <div class="w-100 rounded h-100">
                                <button onClick={() => setStatus('ors-008')}
                                    class="btn h-100 w-100 text-primary d-flex align-items-center justify-content-center"
                                    style={{ flexWrap: 'wrap' }} disabled={loadingStatuses}>
                                    {
                                        loadingStatuses ?
                                            <div className="spinner">
                                                <div className="double-bounce1 bg-primary"></div>
                                                <div className="double-bounce2 bg-primary"></div>
                                            </div>
                                            :
                                            <>
                                                <BsCartCheck />
                                                <p class="mb-0 w-100 text-center" style={{ fontSize: '14px' }}>
                                                    Comprado
                                                </p>
                                            </>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div >
            }

            <Modal show={statusCode} onHide={() => setStatus('')} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Descripción
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="form-control"
                        style={{ height: '300px' }}
                        placeholder="Ingrese una nota..."></textarea>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setStatus('')}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleConfirm}>
                        Guardar cambios
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default RequestedProducts;