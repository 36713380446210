import { useState } from "react";
import useAxios from "../../hooks/useAxios";
import ProviderResponsibleRow from "./ProviderResponsibleRow";
import { Button, Modal } from "react-bootstrap";
import CreateProviderResponsibleModal from "./CreateProviderResponsibleModal";

const ResponsiblesCrud = ({ providerId }) => {

    const [show, setShow] = useState(false);

    const [{ data }, getResponsibles] = useAxios({ url: `/providers/${providerId}/responsibles` }, { useCache: false });

    const handleCloseCreateModal = (value) => {
        if (value) getResponsibles();

        setShow(false);
    }

    return (
        <>
            <div>
                <ul className="list-group">
                    {
                        data?.length > 0 ?
                            data?.map((responsible, i) => {
                                return (
                                    <ProviderResponsibleRow
                                        providerId={providerId}
                                        responsible={responsible}
                                        key={`responsable-${i}`}
                                        onDelete={() => getResponsibles()}
                                    />
                                )
                            })
                            :
                            <li className="list-group-item text-center">
                                No se encontrarón resultados.
                            </li>
                    }
                </ul>
                <br />
                <div className="text-center">
                    <button className="btn btn-primary" onClick={() => setShow('crear-responsible')}>
                        Agregar responsable
                    </button>
                </div>

            </div>
            <CreateProviderResponsibleModal
                providerId={providerId}
                show={show === 'crear-responsible'}
                onClose={handleCloseCreateModal}
            />
        </>
    )
}

export default ResponsiblesCrud;