import useAxios from "../../hooks/useAxios";
import swal from "sweetalert";

const ProviderResponsibleRow = ({ responsible, onDelete, providerId }) => {

    const [{ loading }, deleteResponsible] = useAxios({ url: `providers/${providerId}/responsibles/${responsible?.id}`, method: 'DELETE' }, { manual: true, useCache: false });

    const handleDeleteSelected = () => {
        swal({
            title: "¿Estas seguro?",
            text: "¿quieres eliminar el registro?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteResponsible().then(() => {
                    onDelete?.()
                });
            }
        })
    }

    return (
        <li class="list-group-item d-flex justify-content-between align-items-center">
            {responsible?.user?.name}
            <span disabled={loading} class="badge bg-danger rounded-pill" style={{ cursor: 'pointer' }} onClick={handleDeleteSelected}>
                {
                    loading ?
                        'cargando'
                        :
                        'eliminar'
                }
            </span>
        </li>
    )
}

export default ProviderResponsibleRow;