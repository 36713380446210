import { Button, Modal } from "react-bootstrap"
import useAxios from "../../hooks/useAxios"
import { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import clsx from "clsx";



const CreateProviderResponsibleModal = ({ show, onClose, providerId }) => {

    const [userIds, setUsersIds] = useState([]);

    const [filters, setFilters] = useState({
        search: '',
        page: 1,
        perPage: 10
    });

    const [{ data, loading }, getUsers] = useAxios({ url: `/providers/${providerId}/responsibles/available-users`, params: filters }, { manual: true, useCache: false });

    const [{ loading: storeLoading }, storeResponsible] = useAxios({ url: `/providers/${providerId}/responsibles`, method: 'POST' }, { manual: true, useCache: false })

    const handleCreate = () => {
        storeResponsible({ data: { userIds } }).then(() => {
            onClose?.(true);
        });
    }

    useEffect(() => {
        if (show) {
            getUsers();
        } else {
            setFilters({
                search: '',
                page: 1,
                perPage: 10
            });

            setUsersIds([]);
        }
    }, [show])

    useEffect(() => {
        if (show) {
            getUsers({ params: filters });
        }
    }, [filters, show])

    const handleToggleUserId = (userId) => {
        var values = userIds;
        if (values.includes(userId)) {
            values = values.filter((value) => value !== userId);
        } else {
            values = [...values, userId];
        }

        setUsersIds(values);
    }

    return (
        <Modal show={show} onHide={() => onClose?.()} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    Agregar Responsble
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row align-items-center justify-content-betwen">
                    <div className="col-md-8 mb-3">
                        <input
                            type="text"
                            placeholder="Buscar..."
                            className="form-control"
                            onChange={(e) => setFilters((old) => { return { ...old, search: e.target.value, page: 1 } })}
                            value={filters?.search}
                        />
                    </div>
                    <div className="col-md-4 mb-3 text-md-end  text-center">
                        <p className="mb-0 fw-bold text-primary">
                            Usuarios seleccionados ({userIds?.length})
                        </p>
                    </div>
                </div>
                <div className="table-responsible">
                    <table className="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Nombre</th>
                                <th>Centro de costo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ?
                                    <tr>
                                        <td colSpan={3} className="text-center">
                                            Cargando...
                                        </td>
                                    </tr>
                                    :
                                    data?.data?.length > 0 ?
                                        data?.data?.map((user, i) => {
                                            return (
                                                <tr key={`usuario-${user?.id}`}>
                                                    <td>
                                                        <button onClick={() => handleToggleUserId(user?.id)} type="button" className={clsx(["btn"], {
                                                            'btn-primary': userIds.includes(user?.id),
                                                            'btn-tertiary': !userIds.includes(user?.id)
                                                        })} >
                                                            <FaCheck />
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center gap-2">
                                                            <img src={user?.imagePath} alt={user?.name} style={{ width: 40, height: 40, objectFit: 'cover' }} className="rounded" />
                                                            <div>
                                                                <p className="mb-0 text-black">
                                                                    {user?.name}
                                                                </p>
                                                                <p className="mb-0 text-black">
                                                                    {user?.documentNumber}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-black">
                                                            {user?.costCenter?.name}
                                                        </p>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <tr>
                                            <td colSpan={3} className="text-center">
                                                No se encontrarón resultados.
                                            </td>
                                        </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <select className="form-control"
                            value={filters?.perPage}
                            onChange={(e) => setFilters((old) => { return { ...old, perPage: e.target.value, page: 1 } })}
                        >
                            <option value="10">
                                10
                            </option>
                            <option value="20">
                                20
                            </option>
                            <option value="50">
                                50
                            </option>
                            <option value="100">
                                100
                            </option>
                            <option value="200">
                                200
                            </option>
                        </select>
                    </div>
                    <div className="col-md-9">
                        <div className="d-flex custom-horizontal-scrollbar scrollbar-primary align-items-center" style={{ overflow: 'auto', maxWidth: '100%' }}>
                            {
                                Array.from(Array(data?.meta?.last_page).keys()).map((page, i) => {
                                    return (
                                        <li className="page-item">
                                            <button type="button" className={clsx(["page-link"], {
                                                'bg-primary text-white': filters?.page == (page + 1)
                                            })}

                                                onClick={(e) => setFilters((old) => { return { ...old, page: page + 1 } })}
                                            >
                                                {page + 1}
                                            </button>
                                        </li>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => onClose?.()}>
                    Cerrar
                </Button>
                <Button disabled={!userIds?.length} variant="primary" onClick={handleCreate}>
                    Guardar Cambios
                </Button>
            </Modal.Footer>
        </Modal >
    )
}

export default CreateProviderResponsibleModal